
import {
  computed,
  defineComponent, reactive, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarCasasDecimaisValor from '@/core/components/Tela/SelecionarCasasDecimaisValor.vue';
import SelecionarTipoItem from '@/components/Compartilhados/SelecionarTipoItem.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoTipoCusto from '@/servicos/Cadastros/Precificacao/ServicoTipoCusto';
import { ITipoCusto, ITipoCustoEmpresa } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoItem } from '@/models/Enumeradores/Compartilhados/ETipoItem';

const listaVariaveis = ['ValorTotalProduto', 'ValorCustoAtual', 'QuantidadeProduto', 'QuantidadeEstoqueAtual', 'ValorIcms', 'ValorSubstituicaoTributaria', 'ValorDifal', 'ValorIpi', 'ValorPis', 'ValorCofins', 'ValorIOF', 'ValorImpostoImportacao', 'ValorFrete', 'ValorSeguro', 'ValorOutrasDespesas', 'ValorIcmsFreteConhecimento', 'ValorDespesasAduaneiras', 'ValorDesconto'];
const variaveisIncidencias: Record<string, string[]> = {
  v: listaVariaveis,
  V: listaVariaveis,
  q: listaVariaveis,
  Q: listaVariaveis,
  '-': listaVariaveis,
  '+': listaVariaveis,
  '*': listaVariaveis,
  '/': listaVariaveis,
  '(': listaVariaveis,

};

export interface IOptionMention {
  key: string;
  value: string;
  label: string;
}

export default defineComponent({
  name: 'TipoCustoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarCasasDecimaisValor,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarTipoItem,
    RequisicaoModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoTipoCusto = new ServicoTipoCusto();
    telaBase.identificadorRecurso = 'CADASTRO_TIPOS_CUSTO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_TIPOS_CUSTO';
    const prefix = ref<string>('');
    const state = reactive({
      tipoCusto: {} as ITipoCusto,
    });

    function objetoInicial() {
      state.tipoCusto = {} as ITipoCusto;
      state.tipoCusto.codigo = 0;
      state.tipoCusto.casasDecimais = 2;
      state.tipoCusto.formula = '';
      state.tipoCusto.recursoAtualizacao = '';
      state.tipoCusto.empresas = [];
      state.tipoCusto.ativo = true;
      telaBase.empresasSelecionadas = [];
    }

    function preparaPersistenciaEmpresas() {
      const tipoCustoEmpresas: ITipoCustoEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.tipoCusto.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            tipoCustoEmpresas.push(empresaExistente);
          } else {
            const tipoCustoEmpresa: ITipoCustoEmpresa = { codigo: 0, codigoTipoCusto: state.tipoCusto.codigo, codigoEmpresa };
            tipoCustoEmpresas.push(tipoCustoEmpresa);
          }
        });
      }
      state.tipoCusto.empresas = tipoCustoEmpresas;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (state.tipoCusto.tipoItem === ETipoItem.Servico) {
        state.tipoCusto.formula = '-';
        state.tipoCusto.recursoAtualizacao = '-';
      }

      preparaPersistenciaEmpresas();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTipoCusto.incluir(state.tipoCusto);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoTipoCusto.alterar(state.tipoCusto);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.tipoCusto = await servicoTipoCusto.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.tipoCusto.empresas.length > 0) {
            state.tipoCusto.empresas.forEach((tipoCustoEmpresa) => {
              telaBase.empresasSelecionadas.push(tipoCustoEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    function obterOpcoes(): IOptionMention[] {
      if (UtilitarioGeral.valorValido(prefix.value)) {
        const variaveisDoPrefixo = variaveisIncidencias[prefix.value];
        if (UtilitarioGeral.validaLista(variaveisDoPrefixo)) {
          const variaveisDisponiveis: IOptionMention[] = [];
          variaveisDoPrefixo.forEach((variavel) => {
            variaveisDisponiveis.push({ key: variavel, value: variavel, label: variavel } as IOptionMention);
          });
          return variaveisDisponiveis;
        }
      }

      return [];
    }
    const options = computed(() => obterOpcoes());

    const onSearch = (_: string, val: string) => {
      prefix.value = val;
    };

    function changeFormula() {
      if (UtilitarioGeral.valorValido(state.tipoCusto.formula)) {
        let formulaPreenchida = UtilitarioGeral.instanciaObjetoLocal(state.tipoCusto.formula);

        formulaPreenchida = formulaPreenchida.replaceAll('vV', 'V');
        formulaPreenchida = formulaPreenchida.replaceAll('VV', 'V');
        formulaPreenchida = formulaPreenchida.replaceAll('qQ', 'Q');
        formulaPreenchida = formulaPreenchida.replaceAll('QQ', 'Q');
        formulaPreenchida = formulaPreenchida.replaceAll('qV', 'Q');
        formulaPreenchida = formulaPreenchida.replaceAll('QV', 'Q');
        formulaPreenchida = formulaPreenchida.replaceAll('vQ', 'Q');
        formulaPreenchida = formulaPreenchida.replaceAll('VQ', 'Q');
        state.tipoCusto.formula = formulaPreenchida;
      }
    }
    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      onSearch,
      options,
      changeFormula,
    };
  },
});
