import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IMovimentoFinanceiro, IMovimentoFinanceiroAnexo, IMovimentoFinanceiroFechamento, IMovimentoFinanceiroMarcador,
} from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { IDTOMovimentoFinanceiroCentroCusto } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroCentroCusto';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';
import { ITransferenciaContas } from '@/models/Entidades/Financeiro/TransferenciasContas/ITransferenciaContas';
import { IDTOTransferenciaContas } from '@/models/DTO/Financeiro/TransferenciasContas/IDTOTransferenciaContas';
import { IParametrosConsultaMovimentoFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaMovimentoFinanceiro';
import { IDTOMovimentoFinanceiroExtrato } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroExtrato';
import { IDTOMovimentoFinanceiroAnexoInserir } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroAnexoInserir';

/**
 * Serviço de Movimentos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a movimento financeiro.
 */

class ServicoMovimentoFinanceiro {
    endPoint = 'movimentacoes-contas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscarMovimentos(parametrosConsulta: IParametrosConsultaMovimentoFinanceiro, filtros?: IFiltroGenerico[]): Promise<IDTOMovimentoFinanceiroExtrato> {
      let parametrosAdicionais = '';
      if (UtilitarioGeral.validaCodigo(parametrosConsulta.empresa)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresa=${parametrosConsulta.empresa}`;
      }

      if (UtilitarioGeral.validaCodigo(parametrosConsulta.conta)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Conta=${parametrosConsulta.conta}`;
      }
      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      if (parametrosConsulta.tipoMovimento !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `tipoMovimento=${parametrosConsulta.tipoMovimento}`;
      }

      if (parametrosConsulta.conciliados !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `conciliados=${parametrosConsulta.conciliados}`;
      }

      if (parametrosConsulta.excluidos !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `excluidos=${parametrosConsulta.excluidos}`;
      }

      if (parametrosConsulta.influenciaSaldo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `influenciaSaldo=${parametrosConsulta.influenciaSaldo}`;
      }

      if (UtilitarioGeral.valorValido(parametrosConsulta.buscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `buscaRapida=${parametrosConsulta.buscaRapida}`;
      }

      if (parametrosConsulta.apenasSemNumeroControle !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `apenasSemNumeroControle=${parametrosConsulta.apenasSemNumeroControle}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      return result.data;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsultaMovimentoFinanceiro, filtros?: IFiltroGenerico[]): Promise<IRetornoRelatorio> {
      let parametrosAdicionais = '';
      if (UtilitarioGeral.validaCodigo(parametrosConsulta.empresa)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresa=${parametrosConsulta.empresa}`;
      }

      if (UtilitarioGeral.validaCodigo(parametrosConsulta.conta)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Conta=${parametrosConsulta.conta}`;
      }
      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      if (parametrosConsulta.tipoMovimento !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `tipoMovimento=${parametrosConsulta.tipoMovimento}`;
      }

      if (parametrosConsulta.conciliados !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `conciliados=${parametrosConsulta.conciliados}`;
      }

      if (parametrosConsulta.excluidos !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `excluidos=${parametrosConsulta.excluidos}`;
      }

      if (parametrosConsulta.influenciaSaldo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `influenciaSaldo=${parametrosConsulta.influenciaSaldo}`;
      }

      if (UtilitarioGeral.valorValido(parametrosConsulta.buscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `buscaRapida=${parametrosConsulta.buscaRapida}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterMovimentoConsultaRapida(codigo: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigo.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const lista = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(lista)) {
        return lista[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async lancarMovimento(movimento: IMovimentoFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, movimento);
      return result.data;
    }

    public async atualizarMovimento(movimento: IMovimentoFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${movimento.codigo}`, movimento);
      return result.data;
    }

    public async obterMovimento(codigo: number, empresa: number): Promise<IMovimentoFinanceiro> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirMovimento(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async conciliarMovimentos(movimentos: number[], empresa: number): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < movimentos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Movimentos=${movimentos[contador]}`;
      }
      const result: any = await this.apiERP.put(`${this.endPoint}/conciliacao/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterCentrosCusto(codigo: number, empresa: number): Promise<IDTOMovimentoFinanceiroCentroCusto[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/centros-custo/empresa/${empresa}`);
      return result.data;
    }

    public async inserirAnexos(codigo: number, objeto: IDTOMovimentoFinanceiroAnexoInserir): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/anexos`, objeto);
      return result.data;
    }

    public async obterAnexos(codigo: number, empresa: number): Promise<IMovimentoFinanceiroAnexo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/anexos/empresa/${empresa}`);
      return result.data;
    }

    public async removerAnexo(codigo:number, codigoMovimentoFinanceiroAnexo: number, codigoArquivo: number): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/anexos/${codigoMovimentoFinanceiroAnexo}/arquivo/${codigoArquivo}`);
      return result.data;
    }

    public async obterMarcadores(codigo: number, empresa: number): Promise<IMovimentoFinanceiroMarcador[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/marcadores/empresa/${empresa}`);
      return result.data;
    }

    public async consultarMarcadores(empresas: number[], marcador: string): Promise<string[]> {
      let parametrosAdicionais = '';

      empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });

      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Marcador=${marcador}`;

      const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
      return result.data;
    }

    public async obterTitulosBaixados(movimento: number, empresa: number): Promise<IDTOTituloFinanceiroBaixado[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${movimento}/titulos-baixados/empresa/${empresa}`);
      return result.data;
    }

    public async obterBaixaTitulos(movimento: number, empresa: number): Promise<IBaixaTitulos> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${movimento}/baixa-titulos/empresa/${empresa}`);
      return result.data;
    }

    public async obterValorMovimentos(conta: number, dataInicial: string, dataFinal: string, tipoMovimento: ETipoMovimentoFinanceiro, empresa:number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-movimentos/${conta}/data-inicial/${dataInicial}/data-final/${dataFinal}/tipo/${tipoMovimento}/empresa/${empresa}`);
      return result.data;
    }

    public async obterSaldoConciliado(conta: number, dataInicial: string, dataFinal: string, empresa:number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${conta}/saldo/conciliado/data-inicial/${dataInicial}/data-final/${dataFinal}/empresa/${empresa}`);
      return result.data;
    }

    public async obterSaldoAnterior(conta: number, data: string, empresa:number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${conta}/saldo/anterior/data/${data}/empresa/${empresa}`);
      return result.data;
    }

    public async obterSaldoAtual(conta: number, empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${conta}/saldo/empresa/${empresa}`);
      return result.data;
    }

    public async obterSaldoFinal(conta: number, data: string, empresa:number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${conta}/saldo/final/data/${data}/empresa/${empresa}`);
      return result.data;
    }

    public async fecharPeriodoFinanceiro(fechamentoPeriodo: IMovimentoFinanceiroFechamento): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/fechamentos-periodos`, fechamentoPeriodo);
      return result.data;
    }

    public async obterFechamentosPeriodosMovimentacoesConta(conta: number, empresa: number): Promise<IMovimentoFinanceiroFechamento[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/fechamentos-periodos/conta/${conta}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirFechamentoPeriodoFinanceiro(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/fechamentos-periodos/${codigo}`);
      return result.data;
    }

    public async transferir(transferencia: ITransferenciaContas): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/transferencias`, transferencia);
      return result.data;
    }

    public async estonarTransferencia(transferencia: IDTOTransferenciaContas): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/transferencias/estorno`, transferencia);
      return result.data;
    }

    public async obterTransferencia(movimento: number, empresa:number): Promise<IDTOTransferenciaContas> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${movimento}/transferencias/empresa/${empresa}`);
      return result.data;
    }

    public async validarPermissaoEstorno(empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/validacao-estorno/empresa/${empresa}`);
      return result.data;
    }

    public async validarPermissaoTransferencia(empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/validacao-transferencia/empresa/${empresa}`);
      return result.data;
    }

    public verificaSeMovimentoRelacionadoBaixa(recurso: string): boolean {
      if (recurso === 'BAIXAS_CONTAS_RECEBER' || recurso === 'BAIXAS_CONTAS_PAGAR' || recurso === 'RECEBIMENTOS_MERCADORIAS' || recurso === 'IMPORTACAO_TITULOS_FINANCEIROS') {
        return true;
      }
      return false;
    }

    public verificaSeMovimentoPodeSerExcluido(recurso: string, excluido: boolean, movimentoIndividual: boolean): boolean {
      if (recurso === 'CADASTRO_CONTAS') { return false; }

      if (recurso === 'MOVIMENTACOES_CONTAS' && !excluido) { return true; }

      if (recurso === 'IMPORTACAO_OFX' && movimentoIndividual && !excluido) { return true; }

      return false;
    }
}
export default ServicoMovimentoFinanceiro;
