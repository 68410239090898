import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41c2f080"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ss-menu-corpo"
}
const _hoisted_2 = {
  class: "ant-row",
  style: {"text-align":"center"}
}
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_ctx.storeSistema.state.menus.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeSistema.state.menus, (menu) => {
            return (_openBlock(), _createElementBlock("div", {
              key: menu.codigoModulo,
              class: "ant-col ant-col-xs-6 ant-col-xl-6"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  class: "ss-menu",
                  style: _normalizeStyle('background-color: ' + menu.cor),
                  onClick: ($event: any) => (_ctx.abrirMenuRecursos(menu.codigoModulo))
                }, [
                  _createVNode(_component_icone, {
                    class: "ss-icone-menu",
                    nome: menu.icone
                  }, null, 8, ["nome"])
                ], 12, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(menu.titulo), 1)
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}