import ApiERP from '@/core/conectores/ApiERP';
import { IEmpresa } from '@/models/Entidades/MeuSistema/Empresas/IEmpresa';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IEmpresaCadastroCompartilhado } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaCadastroCompartilhado';
import { IGrupoEmpresa } from '@/models/Entidades/MeuSistema/GrupoEmpresas/IGrupoEmpresa';
/**
 * Serviço de Empresa
 * Fornece todas regras de negócios e lógicas relacionado a empresas.
 */

class ServicoEmpresa {
  endPoint = 'empresas';

  apiERP = new ApiERP();

  public prepararObjetoEnvioAPI(empresa: IEmpresa):IEmpresa {
    const objeto = UtilitarioGeral.instanciaObjetoLocal(empresa);
    objeto.cpfCnpj = UtilitarioMascara.removerMascara(objeto.cpfCnpj);
    objeto.telefone = UtilitarioMascara.removerMascara(objeto.telefone);
    objeto.endereco.cep = UtilitarioMascara.removerMascara(objeto.endereco.cep);
    objeto.simplesNacional.dataAdesao = UtilitarioData.converterDataJson(objeto.simplesNacional.dataAdesao);

    return objeto;
  }

  public async obterEmpresas(): Promise<IEmpresa[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}`);
    return result.data;
  }

  public async obterListaTipoPessoa(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipo-pessoa`);
    return result.data;
  }

  public async obterListaTipoAtividade(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipo-atividade`);
    return result.data;
  }

  public async obterListaRegimeTributario(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/regime-tributario`);
    return result.data;
  }

  public async obterListaRegimeTributarioEspecial(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/regime-tributario-especial`);
    return result.data;
  }

  public async incluir(empresa: IEmpresa): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, empresa);
    return result.data;
  }

  public async alterar(empresa: IEmpresa): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${empresa.codigo}`, this.prepararObjetoEnvioAPI(empresa));
    return result.data;
  }

  public async obter(codigo: number): Promise<IEmpresa> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async enviarLogo(arquivo: any, codigoEmpresa: number): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivo', arquivo);
    const result: any = await this.apiERP.upload(`${this.endPoint}/${codigoEmpresa}/logo`, data);
    return result.data;
  }

  public async removerLogo(linkArquivo: string, codigoEmpresa: number): Promise<IRetornoArquivo> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigoEmpresa}/logo?linkArquivo=${linkArquivo}`);
    return result.data;
  }

  public async obterTokenEmpresa(codigo: number): Promise<string> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/token`);
    return result.data;
  }

  public async obterDadosEmpresaPorToken(token: string): Promise<IEmpresa> {
    const result: any = await this.apiERP.post(`${this.endPoint}/token`, token);
    return result.data;
  }

  public async criarGrupoEmpresa(grupoEmpresa: IGrupoEmpresa): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/grupos`, grupoEmpresa);
    return result.data;
  }

  public async adicionarEmpresaNoGrupo(codigoGrupoEmpresa: number, codigoEmpresa:number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/grupos/${codigoGrupoEmpresa}/empresa/${codigoEmpresa}`);
    return result.data;
  }

  public async atualizarNomeGrupo(codigoEmpresa:number, nome:string, codigoGrupoEmpresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.patch(`${this.endPoint}/${codigoEmpresa}/grupos/${codigoGrupoEmpresa}`, nome);
    return result.data;
  }

  public async obterGrupoEmpresa(): Promise<IGrupoEmpresa> {
    const result: any = await this.apiERP.get(`${this.endPoint}/grupos`);
    return result.data;
  }

  public async obterEmpresasCadastrosCompartilhados(): Promise<IEmpresaCadastroCompartilhado[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/cadastros-compartilhados`);
    return result.data;
  }

  public async atualizarCadastrosCompartilhados(cadastrosCompartilhados: IEmpresaCadastroCompartilhado[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/cadastros-compartilhados`, cadastrosCompartilhados);
    return result.data;
  }
}

export default ServicoEmpresa;
